// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-connected-car-platform-js": () => import("./../../../src/pages/connected-car-platform.js" /* webpackChunkName: "component---src-pages-connected-car-platform-js" */),
  "component---src-pages-connected-car-services-js": () => import("./../../../src/pages/connected-car-services.js" /* webpackChunkName: "component---src-pages-connected-car-services-js" */),
  "component---src-pages-connected-car-use-cases-js": () => import("./../../../src/pages/connected-car-use-cases.js" /* webpackChunkName: "component---src-pages-connected-car-use-cases-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-dealership-management-features-js": () => import("./../../../src/pages/dealership-management-features.js" /* webpackChunkName: "component---src-pages-dealership-management-features-js" */),
  "component---src-pages-dealership-management-pricing-js": () => import("./../../../src/pages/dealership-management-pricing.js" /* webpackChunkName: "component---src-pages-dealership-management-pricing-js" */),
  "component---src-pages-dealership-management-solution-js": () => import("./../../../src/pages/dealership-management-solution.js" /* webpackChunkName: "component---src-pages-dealership-management-solution-js" */),
  "component---src-pages-drivers-js": () => import("./../../../src/pages/drivers.js" /* webpackChunkName: "component---src-pages-drivers-js" */),
  "component---src-pages-fleet-management-benefits-js": () => import("./../../../src/pages/fleet-management-benefits.js" /* webpackChunkName: "component---src-pages-fleet-management-benefits-js" */),
  "component---src-pages-fleet-management-features-js": () => import("./../../../src/pages/fleet-management-features.js" /* webpackChunkName: "component---src-pages-fleet-management-features-js" */),
  "component---src-pages-fleet-management-price-js": () => import("./../../../src/pages/fleet-management-price.js" /* webpackChunkName: "component---src-pages-fleet-management-price-js" */),
  "component---src-pages-fleet-management-service-js": () => import("./../../../src/pages/fleet-management-service.js" /* webpackChunkName: "component---src-pages-fleet-management-service-js" */),
  "component---src-pages-fleet-salgsbetingelser-js": () => import("./../../../src/pages/fleet-salgsbetingelser.js" /* webpackChunkName: "component---src-pages-fleet-salgsbetingelser-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leasing-management-features-js": () => import("./../../../src/pages/leasing-management-features.js" /* webpackChunkName: "component---src-pages-leasing-management-features-js" */),
  "component---src-pages-leasing-management-pricing-js": () => import("./../../../src/pages/leasing-management-pricing.js" /* webpackChunkName: "component---src-pages-leasing-management-pricing-js" */),
  "component---src-pages-leasing-management-solution-js": () => import("./../../../src/pages/leasing-management-solution.js" /* webpackChunkName: "component---src-pages-leasing-management-solution-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-roi-calculator-fleet-js": () => import("./../../../src/pages/roi-calculator-fleet.js" /* webpackChunkName: "component---src-pages-roi-calculator-fleet-js" */),
  "component---src-pages-roi-calculator-logbook-js": () => import("./../../../src/pages/roi-calculator-logbook.js" /* webpackChunkName: "component---src-pages-roi-calculator-logbook-js" */),
  "component---src-pages-sign-up-driver-app-js": () => import("./../../../src/pages/sign-up-driver-app.js" /* webpackChunkName: "component---src-pages-sign-up-driver-app-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-vehicle-care-js": () => import("./../../../src/pages/vehicle-care.js" /* webpackChunkName: "component---src-pages-vehicle-care-js" */),
  "component---src-pages-vehicle-care-management-js": () => import("./../../../src/pages/vehicle-care-management.js" /* webpackChunkName: "component---src-pages-vehicle-care-management-js" */),
  "component---src-pages-workshop-management-pricing-js": () => import("./../../../src/pages/workshop-management-pricing.js" /* webpackChunkName: "component---src-pages-workshop-management-pricing-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blogpost-js": () => import("./../../../src/templates/blogpost.js" /* webpackChunkName: "component---src-templates-blogpost-js" */)
}

